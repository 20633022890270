import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import { FaRegEye } from "react-icons/fa"
import { WiDirectionUp } from "react-icons/wi"
import { useLocationState } from "use-location-state"
import { TTheme } from "../../types"
import { BlockContent } from "../components/block-content"
import { DefaultButton } from "../components/button"
import { ContactForm } from "../components/contact-form"
import { Container } from "../components/container"
import { Grid } from "../components/grid"
import { H1, H2, H4 } from "../components/headings"
import { Modal } from "../components/modal"
import SEO from "../components/seo"
import { Typography } from "../components/typography"
import { ViewInRoom } from "../components/view-in-room"
import mq from "../theme/mq"
import styled from "../theme/styled"

const ArtistLink = styled(H4)(({ theme }) => ({
	textTransform: `uppercase`,
	textDecoration: `none`,
	display: `flex`,
	width: `100%`,
	justifyContent: `flex-start`,
	alignItems: `center`,
	marginTop: theme.spacing.xs,
	marginBottom: theme.spacing.l,
	svg: {
		transform: `rotate(90deg)`,
		display: `block`,
		width: `1em`,
		height: `1em`,
		flexShrink: 0,
		transition: `transform 0.4s ease`,
		marginLeft: theme.spacing.xxs,
	},
	":hover svg": {
		transition: `transform 0.1s ease`,
		transform: `rotate(90deg) translateY(-5px)`,
	},
})).withComponent(Link)

export const query = graphql`
	query ArtTemplate($id: String!, $artistId: String!) {
		info: sanityInfo(_id: { eq: "info" }) {
			name
			phoneNumber
		}
		siteSettings: sanitySiteSettings(_id: { eq: "siteSettings" }) {
			title
		}
		art: sanityArt(id: { eq: $id }) {
			title
			material {
				name
			}
			height
			width
			depth
			price
			framed
			sold
			artist {
				name
				slug {
					current
				}
			}
			image {
				asset {
					fixed(width: 800, toFormat: JPG) {
						srcSet
						src
						srcSetWebp
						aspectRatio
						base64
					}
				}
				crop {
					bottom
					left
					right
					top
				}
			}
			id
			slug {
				current
			}
			description: _rawDescription
		}
		artwork: allSanityArt(filter: { artist: { id: { eq: $artistId } } }) {
			nodes {
				createdAt: _createdAt(difference: "seconds")
				id
				title
				material {
					name
				}
				price
				sold
				artist {
					id
					name
				}
				image {
					asset {
						small: fixed(width: 400) {
							src
							aspectRatio
						}
						large: fixed(width: 800) {
							src
						}
					}
				}
				slug {
					current
				}
			}
		}
	}
`

export default function ArtTemplate({ data }: { data: any }) {
	const [activeTab, setActiveTab] = useState<"about" | "more">("about")
	const artistSlug = data.art.artist.slug
		? data.art.artist.slug.current
		: null
	const artistLink = artistSlug
		? `/artist/${data.art.artist.slug.current}`
		: null

	return (
		<>
			<SEO
				title={data.art.title}
				description={
					data.art.description
						? data.art.description
								.map((block: any) =>
									block.children
										.map((child: any) => child.text)
										.join(" ")
								)
								.join(" ")
						: ""
				}
				image={data.art.image.asset.fixed.src}
			/>
			<Container
				css={(theme: TTheme) => ({
					marginBottom: theme.spacing.xxl,
					[mq.large]: {
						padding: `0 12.5%`,
					},
					[mq.container]: {
						padding: `0 12.5%`,
					},
				})}
			>
				<div
					css={(theme: TTheme) => ({
						margin: `${theme.spacing.m} 0`,
						[mq.medium]: {
							display: `grid`,
							gridTemplateColumns: `repeat(2, 1fr)`,
							gridColumnGap: theme.spacing.l,
							margin: `${theme.spacing.xxl} 0`,
						},
						[mq.large]: {
							gridColumnGap: theme.spacing.xl,
						},
						[mq.xlarge]: {
							gridColumnGap: theme.spacing.xxxl,
						},
						[mq.xxlarge]: {
							gridColumnGap: theme.spacing.xxxxl,
							margin: `${theme.spacing.xxxxl} 0`,
						},
					})}
				>
					<div>
						<div
							css={{
								position: `relative`,
								width: `100%`,
								height: 0,
								overflow: `hidden`,
								paddingBottom: `${100 /
									data.art.image.asset.fixed.aspectRatio}%`,
							}}
						>
							<img
								src={data.art.image.asset.fixed.src}
								srcSet={data.art.image.asset.fixed.srcSet}
								css={{
									position: `absolute`,
									top: 0,
									left: 0,
									width: `100%`,
									height: `100%`,
									zIndex: 1,
								}}
								width={700}
								height={Math.round(
									700 *
										(1 /
											data.art.image.asset.fixed
												.aspectRatio)
								)}
							/>
						</div>
						<ViewInRoomArea
							id={data.art.id}
							imageSrc={data.art.image.asset.fixed.src}
							canvasWidth={data.art.width}
							canvasHeight={data.art.height}
						/>
					</div>
					<div>
						<H1
							css={(theme: TTheme) => ({
								margin: `${theme.spacing.l} 0 ${theme.spacing.xl}`,
								[mq.medium]: {
									margin: 0,
								},
							})}
						>
							{data.art.title}
						</H1>
						<Typography>{data.art.material.name}</Typography>
						<Typography>
							{Math.round(data.art.height / 10)} x{" "}
							{Math.round(data.art.width / 10)}
							{data.art.depth == 0
								? ""
								: " x " + Math.round(data.art.depth / 10)}{" "}
							cm{" "}
						</Typography>
						{artistLink ? (
							<ArtistLink to={artistLink}>
								{data.art.artist.name}
								<WiDirectionUp />
							</ArtistLink>
						) : (
							<Typography>{data.art.artist.name}</Typography>
						)}
						<hr
							css={(theme: TTheme) => ({
								display: `block`,
								height: `1px`,
								border: 0,
								borderTop: `1px solid ${theme.colours.primary}`,
								padding: 0,
								marginTop: theme.spacing.m,
								marginBottom: theme.spacing.l,
							})}
						/>
						<Typography
							size="l"
							css={(theme: TTheme) => ({
								alignItems: `center`,
								display: `flex`,
								whiteSpace: `pre-wrap`,
							})}
						>
							£{data.art.price}{" "}
							{data.art.framed ? (
								<span
									css={(theme: TTheme) => ({
										fontSize: theme.font.size.s,
									})}
								>
									- Ready framed
								</span>
							) : null}
						</Typography>
						<Typography
							size="xxs"
							css={{
								lineHeight: `1em`,
							}}
						>
							plus shipping
						</Typography>
						{data.art.sold ? (
							<Typography
								size="l"
								css={(theme: TTheme) => ({
									color: `#c00000`,
									marginTop: theme.spacing.xl,
								})}
							>
								SOLD
							</Typography>
						) : (
							<InquireNow
								id={data.art.id}
								siteTitle={data.siteSettings.title}
								contactDetailsPhoneNumber={
									data.info.phoneNumber
								}
								contactDetailsName={data.info.name}
								artworkTitle={data.art.title}
								artistName={data.art.artist.name}
							/>
						)}
					</div>
				</div>
				<div
					css={(theme: TTheme) => ({
						display: `flex`,
						borderBottom: `1px solid ${theme.colours.tones.neutral}`,
						marginBottom: theme.spacing.xxl,
					})}
				>
					<Tab
						isActive={activeTab === "about"}
						onClick={() => setActiveTab("about")}
					>
						About the work
					</Tab>
					<Tab
						isActive={activeTab === "more"}
						onClick={() => setActiveTab("more")}
					>
						More by the artist
					</Tab>
				</div>
				{activeTab === "more" ? (
					<Grid items={data.artwork.nodes} />
				) : (
					<>
						<H2
							css={(theme: TTheme) => ({
								marginBottom: theme.spacing.xl,
							})}
						>
							{data.art.title}
						</H2>
						<BlockContent body={data.art.description} />
					</>
				)}
			</Container>
		</>
	)
}

function ViewInRoomArea({
	id,
	imageSrc,
	canvasHeight,
	canvasWidth,
}: {
	id: string
	imageSrc: string
	canvasWidth: number
	canvasHeight: number
}) {
	const [isOpen, setIsOpen] = useLocationState(`${id}-viewinroom`, false)

	const onOpen = () => setIsOpen(true, { method: "push" })
	const onClose = () => window.history.back()

	return (
		<>
			<Typography
				size="xxs"
				onClick={onOpen}
				css={(theme: TTheme) => ({
					marginTop: theme.spacing.s,
					cursor: `pointer`,
					display: `flex`,
					alignItems: `center`,
					justifyContent: `center`,
				})}
			>
				View in room
				<FaRegEye
					css={(theme: TTheme) => ({
						marginLeft: theme.spacing.s,
						width: `12px`,
						height: `12px`,
					})}
				/>
			</Typography>
			<ViewInRoom
				canvasHeight={canvasHeight / 10}
				canvasWidth={canvasWidth / 10}
				imageSrc={imageSrc}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	)
}

function InquireNow({
	id,
	contactDetailsPhoneNumber,
	siteTitle,
	contactDetailsName,
	artworkTitle,
	artistName,
}: {
	id: string
	contactDetailsPhoneNumber: string
	siteTitle: string
	contactDetailsName: string
	artworkTitle: string
	artistName: string
}) {
	const [isOpen, setIsOpen] = useLocationState(`${id}-contactdetails`, false)

	const onOpen = () => setIsOpen(true, { method: "push" })
	const onClose = () => window.history.back()

	return (
		<>
			<DefaultButton
				css={(theme: TTheme) => ({
					marginTop: theme.spacing.xxl,
					marginBottom: theme.spacing.xxxl,
					display: `block`,
					width: `100%`,
					[mq.medium]: {
						marginTop: theme.spacing.l,
						marginBottom: 0,
					},
				})}
				onClick={onOpen}
			>
				Inquire now
			</DefaultButton>
			<Modal isOpen={isOpen} onClose={onClose}>
				<H2
					css={(theme: TTheme) => ({
						marginBottom: theme.spacing.m,
						[mq.large]: {
							marginBottom: theme.spacing.l,
						},
						[mq.xxlarge]: {
							marginBottom: theme.spacing.xxl,
						},
						textAlign: `center`,
					})}
				>
					{siteTitle}
				</H2>
				<Typography size="xs">
					Call {contactDetailsName} on{" "}
					<a
						href={`tel:${contactDetailsPhoneNumber}`}
						css={(theme: TTheme) => ({
							color: theme.colours.primary,
						})}
					>
						{contactDetailsPhoneNumber}
					</a>
				</Typography>
				<Typography
					size="xs"
					css={(theme: TTheme) => ({
						marginBottom: theme.spacing.xl,
					})}
				>
					or use the contact form below
				</Typography>
				<ContactForm
					subject={`Enquiry about - ${artworkTitle} ${artistName}`}
				/>
			</Modal>
		</>
	)
}

const Tab = styled(Typography)<{ isActive: boolean }>(props => ({
	cursor: `pointer`,
	marginBottom: `-1px`,
	color: props.isActive
		? props.theme.colours.primary
		: props.theme.colours.tones.neutral,
	borderBottom: props.isActive
		? `1px solid ${props.theme.colours.primary}`
		: `1px solid ${props.theme.colours.tones.neutral}`,
	":hover": {
		color: props.theme.colours.primary,
		borderBottom: `1px solid ${props.theme.colours.primary}`,
	},
	":not(:last-child)": {
		marginRight: props.theme.spacing.xl,
	},
	fontSize: `1.4rem`,
	padding: `${props.theme.spacing.xs} 0`,
	[mq.medium]: {
		padding: 0,
		fontSize: `1.6rem`,
	},
	[mq.large]: {
		fontSize: `1.8rem`,
	},
}))
