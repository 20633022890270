import React, { HTMLProps } from "react"
import { animated, useTransition } from "react-spring"
import { TTheme } from "../../types"
import { Typography } from "./typography"
import { IoIosCloseCircleOutline } from "react-icons/io"
import sofaImage from "../images/sofa.png"
import mq from "../theme/mq"
import { createPortal } from "react-dom"

const scale = 220

const portalRoot = typeof document !== `undefined` ? document.body : null

export function ViewInRoom({
	imageSrc,
	canvasWidth,
	canvasHeight,
	isOpen,
	onClose,
}: HTMLProps<HTMLDivElement> & {
	imageSrc: string
	canvasWidth: number
	canvasHeight: number
	isOpen: boolean
	onClose: () => void
}) {
	const transitions = useTransition(isOpen, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	})

	if (!portalRoot) {
		return null
	}

	return createPortal(
		<>
			{transitions.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							style={props}
							key={key}
							css={(theme: TTheme) => ({
								position: `fixed`,
								top: 0,
								left: 0,
								height: `100vh`,
								width: `100vw`,
								display: `flex`,
								alignItems: `center`,
								justifyContent: `center`,
								zIndex: theme.zIndex.modal,
								background: `#c4c4c4`,
								backgroundImage: `linear-gradient(180deg, #c4c4c4 60%, #858585 100%)`,
								overflow: `auto`,
								padding: `30px`,
							})}
						>
							<div
								css={{
									position: `fixed`,
									top: 0,
									left: 0,
									padding: `40px`,
									cursor: `pointer`,
									[mq.large]: {
										padding: `50px`,
									},
									":hover svg": {
										transform: `scale(1.1)`,
									},
								}}
								onClick={onClose}
							>
								<IoIosCloseCircleOutline
									css={{
										transition: `transform 0.3s ease`,
										width: `40px`,
										height: `40px`,
										[mq.large]: {
											width: `50px`,
											height: `50px`,
										},
									}}
								/>
							</div>
							<div
								css={{
									maxWidth: `800px`,
									width: `100%`,
									maxHeight: `100vh`,
								}}
								onClick={e => e.stopPropagation()}
							>
								<div
									css={{
										width: `${(canvasWidth / 220) * 100}%`,
										margin: `0 auto`,
									}}
								>
									<div
										css={{
											width: `100%`,
											height: 0,
											position: `relative`,
											marginTop: `30px`,
											paddingBottom: `${(canvasHeight /
												canvasWidth) *
												100}%`,
											background: `url(${imageSrc})`,
											":before": {
												content: `' '`,
												display: "table",
											},
										}}
									>
										<img
											src={imageSrc}
											css={{
												position: `absolute`,
												top: 0,
												left: 0,
												width: `100%`,
												height: `100%`,
												zIndex: 1,
												boxShadow: `2px 2px 2px rgba(0,0,0,0.4)`,
											}}
										/>
									</div>
								</div>
								<img
									src={sofaImage}
									css={{
										display: `block`,
										width: `100%`,
										height: `auto`,
										marginTop: `20px`,
										marginBottom: `10px`,
										[mq.large]: {
											marginTop: `40px`,
											marginBottom: `20px`,
										},
										[mq.xxlarge]: {
											marginTop: `70px`,
											marginBottom: `30px`,
										},
									}}
								/>
								<div
									css={{
										position: `relative`,
										width: `100%`,
										borderTop: `1px solid #000`,
										":after, :before": {
											content: `' '`,
											position: `absolute`,
											width: `1px`,
											height: `11px`,
											background: `#000`,
											top: `-6px`,
										},
										":after": {
											left: 0,
										},
										":before": {
											right: 0,
										},
									}}
								/>
								<Typography
									css={{
										textAlign: `center`,
										marginTop: `20px`,
										marginBottom: `30px`,
									}}
								>
									{scale}cm
								</Typography>
							</div>
						</animated.div>
					)
			)}
		</>,
		portalRoot as HTMLElement
	)
}
