import React, { HTMLProps } from "react"
import { animated, useSpring } from "react-spring"
import { TTheme } from "../../types"
import mq from "../theme/mq"

function Modal({
	children,
	isOpen,
	onClose,
}: HTMLProps<HTMLDivElement> & { isOpen: boolean; onClose: () => void }) {
	const inner = useSpring({
		from: { opacity: 0, transform: "scale(0.8)" },
		to: {
			opacity: isOpen ? 1 : 0,
			transform: isOpen ? "scale(1)" : "scale(0.8)",
		},
	})
	const outer = useSpring({
		from: { backgroundColor: `rgba(0,0,0,0)`, pointerEvents: `none` },
		to: {
			backgroundColor: isOpen ? `rgba(0,0,0,0.54)` : `rgba(0,0,0,0)`,
			pointerEvents: isOpen ? `auto` : `none`,
		},
	})

	return (
		<animated.div
			style={outer}
			css={(theme: TTheme) => ({
				position: `fixed`,
				top: 0,
				left: 0,
				height: `100vh`,
				width: `100vw`,
				display: `flex`,
				alignItems: `center`,
				justifyContent: `center`,
				zIndex: theme.zIndex.modal,
				padding: theme.spacing.xl,
			})}
			onClick={onClose}
		>
			<animated.div
				style={inner}
				css={(theme: TTheme) => ({
					border: `1px solid ${theme.colours.primary}`,
					background: theme.colours.surface,
					width: `100%`,
					maxWidth: `420px`,
					height: `auto`,
					maxHeight: `calc(100vh - ${theme.spacing.l})`,
					overflow: `auto`,
				})}
				onClick={e => e.stopPropagation()}
			>
				<div
					css={(theme: TTheme) => ({
						padding: theme.spacing.m,
						[mq.large]: {
							padding: theme.spacing.l,
						},
						[mq.xxlarge]: {
							padding: theme.spacing.xxl,
						},
					})}
				>
					{children}
				</div>
			</animated.div>
		</animated.div>
	)
}

export { Modal }
